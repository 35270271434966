import React from 'react';
import { Layout, Section, Link } from 'components';
import './thank-you.scss';

const ThankYou = () => (
  <Layout>
    <Section className="thank-you-page">
      <h1>Thank you!</h1>
      <p>A member of our team will be in touch shortly.</p>
      <Link to="/">Back to home</Link>
    </Section>
  </Layout>
);
export default ThankYou;
